@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&display=swap");

* {
  font-family: Mukta !important;
}

body {
  background-color: #130f40;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  .hide-small {
    display: none;
  }
}

.consent-button-hover {
  &:hover {
    filter: brightness(1.2);
    cursor: pointer;
  }

  &:active {
    filter: brightness(1.5);
    cursor: pointer;
  }
}

a {
  text-decoration: none !important;
}
