@import url(https://fonts.googleapis.com/css2?family=Mukta:wght@200;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: #ffffff;
}

*{font-family:Mukta !important}body{background-color:#130f40;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}*{box-sizing:border-box}@media(max-width: 1000px){.hide-small{display:none}}.consent-button-hover:hover{-webkit-filter:brightness(1.2);filter:brightness(1.2);cursor:pointer}.consent-button-hover:active{-webkit-filter:brightness(1.5);filter:brightness(1.5);cursor:pointer}a{text-decoration:none !important}
